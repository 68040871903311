import React from 'react';
import classNames from 'classnames';

import './Button.css';
import { ReactComponent as Spinner } from '../../assets/icons/loader-circle.svg';

function Button({ title, onClick, children, disabled, type, className, isLoading }) {
  const buttonStyle = classNames(`ailight-button ${className}`, { "empty-ailight-button": type === 'empty' })


  return (
    <button className={buttonStyle} title={title} onClick={onClick} disabled={disabled || isLoading}>
      {isLoading && <div className="button-spinner-wrapper">
        <Spinner className="button-spinner" />
      </div>}
      {children}
    </button>
  );
}

export default Button;
