import { useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';

import Google from "../img/google.png";
import TopBar from "../components/TopBar";
import './Login.css';
import LoginFooter from '../components/LoginFooter/LoginFooter'
import { ReactComponent as Twitter } from '../assets/icons/twitter.svg';
import { ReactComponent as Facebook } from '../assets/icons/facebook.svg';
import Button from '../components/Button/Button'

const AUTH0_CUSTOM_DOMAIN = process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN || window._env_.REACT_APP_AUTH0_CUSTOM_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || window._env_.REACT_APP_AUTH0_CLIENT_ID;
const BASE_URL = process.env.REACT_APP_BASE_URL || window._env_.REACT_APP_BASE_URL;

const Login = () => {

    const { loginWithRedirect } = useAuth0();
    const [loginMsg, setLoginMsg] = useState("");

    const google = () => {
        loginWithRedirect({
            connection: 'google-oauth2',
            appState: { targetUrl: '/' }
        });
    };

    const twitter = () => {
        loginWithRedirect({
            connection: 'twitter',
            appState: { targetUrl: '/' }
        });
    };

    const facebook = () => {
        loginWithRedirect({
            connection: 'facebook',
            appState: { targetUrl: '/' }
        });
    };

    const openRegisterIframe = () => {
        window.location.href = `https://${AUTH0_CUSTOM_DOMAIN}/authorize?client_id=${AUTH0_CLIENT_ID}&response_type=code&screen_hint=signup&redirect_uri=${BASE_URL}/registration-success&scope=openid%20profile%20email`;
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        let username = event.target.username.value;
        // if username does not end with @ailight.com, add the postfix
        if (!username.endsWith('@ailight.com')) {
            username = username + '@ailight.com';
        }
        const password = event.target.password.value;

        try {
            await loginWithRedirect({
                authorizationParams: {
                    redirectUri: window.location.origin,
                    email: username,
                    password: password,
                    connection: "Username-Password-Authentication",
                },
                appState: {
                    targetUrl: window.location.href
                },
            });
        } catch (error) {
            console.error('Login failed', error);
        }
    };
    return (
        <>
            <div className="login-wrapper">
                <TopBar className='fixed-bar' />
                <div className="login-welcome-container">
                    <div className="login-welcome-content">
                        Welcome back!
                    </div>
                </div>
                <div className="login-form-container">
                    <div className="login-form-content">
                        <Button onClick={openRegisterIframe} className="login-form-signup-button">Sign Up</Button>
                        <div className="login-form-separator-wrapper">
                            <div className="login-form-separator-stroke" />
                            <div className="login-form-separator-label">
                                or
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="login-form">
                            <span className="login-title">Login</span>
                            <input type="text" name="username" placeholder="Username" className="login-form-full-width" />
                            <input type="password" name="password" placeholder="Password" className="login-form-full-width" />
                            {loginMsg && <p className="login-msg login-form-full-width">{loginMsg}</p>}
                            <button className="submit login-form-full-width" type="submit">Login</button>
                        </form>
                        <div className="login-form-separator-wrapper">
                            <div className="login-form-separator-stroke" />
                            <div className="login-form-separator-label">
                                or
                            </div>
                        </div>
                        <div className="login-form-external-login-buttons-wrapper">
                            <div className="login-form-social-login-button google" onClick={google}>
                                <img src={Google} alt="" className="social-icon" />
                                Google
                            </div>
                            <div className="login-form-social-login-button facebook" onClick={facebook}>
                                <Facebook className="social-icon login-svg-icon" />
                                Facebook
                            </div>
                            <div className="login-form-social-login-button twitter" onClick={twitter}>
                                <Twitter className="social-icon login-svg-icon" />
                                Twitter
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <LoginFooter />
        </>
    );
};

export default Login;
