import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import './RegistrationSuccess.css';
import { ReactComponent as SuccessIcon } from '../assets/icons/circle-check.svg';
import TopBar from "../components/TopBar";

const RegistrationSuccess = () => {
    const [countdown, setCountdown] = useState(7);
    
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prev) => {
                if (prev === 0) {
                    navigate('/analysis/media');
                }

                return prev === 0 ? 0 : prev - 1}
            );
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="success-page-container">
            <TopBar className='fixed-bar' />
            <div className="success-page-central-container">
                <SuccessIcon className="success-page-success-icon" />
                <div className="success-page-welcome-message">Welcome to AI Light</div>
                <div className="success-page-description">You will soon be redirected in {countdown} seconds to AI Light:<br />
                    Uncover the <span className="success-page-highlighted-text">Truth</span>.<br />
                    Expose the <span className="success-page-highlighted-text">Fakes</span>.<br />
                    Defeat <span className="success-page-highlighted-text">Misinformation</span> with AI-Powered Cybersecurity.
                </div>
            </div>
        </div>
    );
};

export default RegistrationSuccess;
