import React from 'react';

import './LoginFooter.css';
import robotIcon from '../../assets/images/ailight-robot-logo.png';

const LoginFooter = () => {
    return (
        <footer className="login-footer-container">
            <div className="login-footer-description">
                <a href="https://ailight.tech" className="login-footer-link">
                    <img className="login-footer-logo" src={robotIcon} />
                </a>
                <span className="login-footer-description-text">Defeat Misinformation with AI-Cybersecurity</span>
                <span className="login-footer-description-text" style={{ fontSize: "14px" }}>Copyright © 2024 AI Light. All rights reserved</span>
            </div>
            <div className="login-footer-menu">
                <div className="login-footer-title">Quick Links</div>
                <a href="https://ailight.tech" className="login-footer-link">
                    <span className="login-footer-description-text">Home</span>
                </a>
                <a href="https://ailight.tech/about/" className="login-footer-link">
                    <span className="login-footer-description-text">About</span>
                </a>
                <a href="https://ailight.tech/package/" className="login-footer-link">
                    <span className="login-footer-description-text">Package</span>
                </a>
                <a href="https://ailight.tech/contact/" className="login-footer-link">
                    <span className="login-footer-description-text">Contact</span>
                </a>
            </div>
            <div className="login-footer-menu">
                <div className="login-footer-title">Information</div>
                <a href="https://ailight.tech/contact/" className="login-footer-link">
                    <span className="login-footer-description-text">Terms and Conditions</span>
                </a>
                <a href="https://ailight.tech/contact/" className="login-footer-link">
                    <span className="login-footer-description-text">Privacy Policy</span>
                </a>
                <a href="https://ailight.tech/contact/" className="login-footer-link">
                    <span className="login-footer-description-text">Cancellation Policy</span>
                </a>
            </div>
        </footer>
    );
};

export default LoginFooter;
