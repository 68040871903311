import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import './MainWorkArea.css';
import MediaAnalysis from './Pages/MediaAnalysis';
import TextAnalysis from './Pages/TextAnalysis'
import ComingSoon from './Pages/ComingSoon';

function MainWorkArea(componentData) {
  return (
    <div className="main-work-area-wrapper">
      <Routes>
        <Route path="/analysis/media" element={<MediaAnalysis {...componentData} />} />
        <Route path="/" element={<Navigate to="/analysis/media" replace />} />
        <Route path="/analysis" element={<Navigate to="/analysis/media" replace />} />
        <Route path="/analysis/text" element={<TextAnalysis {...componentData} />} />
        <Route path="/analysis/audio" element={<ComingSoon />} />
        <Route path="/media-feed/feed" element={<ComingSoon />} />
        <Route path="/media-feed/connect" element={<ComingSoon />} />
      </Routes>
    </div>
  );
}

export default MainWorkArea;
