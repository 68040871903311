import React from 'react';

import './ComingSoon.css';
import { ReactComponent as Atom } from '../../../assets/images/atom.svg';

function ComingSoon() {

  return (
    <div className="coming-soon-container">
      <div className="coming-soon-content-wrapper">
        Coming soon...
        <Atom className="atom-image" />
      </div>
    </div>
  );
}

export default ComingSoon;
