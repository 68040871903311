import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import FullScreenLoader from '../components/FullScreenLoader/FullScreenLoader';

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || window._env_.REACT_APP_AUTH0_DOMAIN;

const PaymentSuccess = () => {
  const { isAuthenticated, isLoading, error, user, logout, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${AUTH0_DOMAIN}/api/v2/`,
            scope: 'read:current_user'
          }
        });

        // Fetch existing user_metadata in order to not lose other params when set
        const response = await fetch(`https://${AUTH0_DOMAIN}/api/v2/users/${user.sub}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        const currentUserMetadata = data.user_metadata || {};

        // Update the user's app_metadata with isPayingMember: true
        const updateResponse = await fetch(
          `https://${AUTH0_DOMAIN}/api/v2/users/${user.sub}`,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_metadata: {
                ...currentUserMetadata,
                isPayingMember: true 
              },
            }),
          }
        );

        if (!updateResponse.ok) {
          throw new Error(`Error updating metadata: ${updateResponse.statusText}`);
        }

        window.location.href = "/";
      } catch (error) {
        console.error('Error fetching metadata:', error);
        logout();
      }
    };

    if (user) {
      fetchMetadata();
    }
  }, [isAuthenticated, user, isLoading, error, getAccessTokenSilently]);

  return <FullScreenLoader />
};

export default PaymentSuccess;
