import React, { useState } from 'react';

import './SideNavBar.css';
import { ReactComponent as Radio } from '../../assets/icons/radio.svg';
import { ReactComponent as MediaIcon } from '../../assets/icons/film.svg';
import { ReactComponent as TextIcon } from '../../assets/icons/text.svg';
import { ReactComponent as AudioIcon } from '../../assets/icons/audio.svg';
import { ReactComponent as AnalyzeIcon } from '../../assets/icons/ai.svg';
import { ReactComponent as ConnectIcon } from '../../assets/icons/unplug.svg';
import { ReactComponent as FeedIcon } from '../../assets/icons/newspaper.svg';

import NavCategory from './NavElement/NavCategory';

const navTree = [
  {
    navCategoryName: "Fraud analysis", id: "analysis", Icon: AnalyzeIcon, navChildren: [
      { navElementName: "Media", path: '/analysis/media', Icon: MediaIcon },
      { navElementName: "Text", path: '/analysis/text', Icon: TextIcon },
      { navElementName: "Audio", path: '/analysis/audio', Icon: AudioIcon },
    ]
  },
  {
    navCategoryName: "Media feed", id: "mediaFeed", Icon: Radio, navChildren: [
      { navElementName: "Feed", path: '/media-feed/feed', Icon: FeedIcon },
      { navElementName: "Connect", path: '/media-feed/connect', Icon: ConnectIcon }
    ]
  }
];

function SideNavBar() {
  const [expandedCategories, setExpandedCategories] = useState(["analysis"]);

  const toggleCategory = categoryId => {
    setExpandedCategories(currentlyExpandedCategoryIds => {
      if (currentlyExpandedCategoryIds.includes(categoryId)) {
        return currentlyExpandedCategoryIds.filter(currentlyExpandedCategoryId => currentlyExpandedCategoryId !== categoryId);
      } else {
        return [...currentlyExpandedCategoryIds,categoryId];
      }
    });
  }

  return (
    <aside className="side-nav-bar">
      {navTree.map(category => {
        return <NavCategory
          category={category}
          key={category.id}
          isExpanded={expandedCategories.includes(category.id)}
          toggleCategory={toggleCategory} />
      })}
    </aside>
  );
}

export default SideNavBar;
