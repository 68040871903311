// Import required libraries
import React from 'react';
import classNames from "classnames";

import './NavItem.css';

function NavItem({ item, isSelected, selectItem }) {
  const { navElementName, id, Icon } = item;

  const navitemClasses = classNames("nav-item", {
    "nav-item-active": isSelected,
  });

  return (
    <div className={navitemClasses} onClick={() => selectItem(id)}>
      <Icon />{navElementName}
    </div>
  );
}

export default NavItem;
