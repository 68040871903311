import React from 'react'

import './FullElementLoader.css';
import { ReactComponent as Spinner } from '../../assets/icons/loader-circle.svg';


const FullElementLoader = () => {
  return <div className="full-element-loader-wrapper">
    <Spinner className="basic-spinner" />
  </div>
}

export default FullElementLoader