import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import './app.css';
import Home from "./pages/Home";
import Login from "./pages/Login";
import RegistrationSuccess from './pages/RegistrationSuccess'
import Billing from "./pages/Billing";
import PuddlePayment from "./pages/PuddlePayment";
import PaymentSuccess from "./pages/PaymentSuccess";
import KnownFakesPage from "./pages/KnownFakes";
import WhatsappMessageSender from "./pages/WhatsappBroadcast";
import store from "./store/store";
import Modal from "./components/Modal/Modal";

const internalAilightMails = ['guy@ailight.tech', 'morin@ailight.tech', 'justas@ailight.tech',
  'nadav.ziv@ailight.tech', 'yuval.friedman@ailight.tech'];

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || window._env_.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || window._env_.REACT_APP_AUTH0_CLIENT_ID;

const AuthenticatedRoute = ({ setAppUser, setExtraData, requiredRoles, children }) => {
  const { isAuthenticated, isLoading, error, user, getIdToken, logout, getAccessTokenSilently } = useAuth0();
  const [userRoles, setUserRoles] = useState([]);
  const [rolesLoading, setRolesLoading] = useState(true);
  const [isPayingMemberLoading, setIsPayingMemberLoading] = useState(true);
  const [isPayingMember, setIsPayingMember] = useState(true);

  useEffect(() => {
    const getUserRoles = async () => {
      if (isAuthenticated) {
        try {
          // const claims = await getIdTokenClaims();
          // const roles = claims['https://dev-rt1gs3l7ub082gs0.us.auth0.com/roles'] || []; // TODO - make it work
          const roles = internalAilightMails.includes(user.email) ? ['admin'] : ['user'];
          setUserRoles(roles);
          setAppUser(user);
        } catch (error) {
          console.error('Error fetching user roles:', error);
        } finally {
          setRolesLoading(false); // Finished loading roles
        }
      } else {
        setUserRoles([]);
        setAppUser(null);
        setRolesLoading(false); // reset loading roles
      }
    };

    const fetchMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${AUTH0_DOMAIN}/api/v2/`,
            scope: 'profile email read:current_user update:current_user_metadata'
          }
        });

        const response = await fetch(`https://${AUTH0_DOMAIN}/api/v2/users/${user.sub}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Correct format for the authorization header
            'Content-Type': 'application/json', // Ensure you have the right content-type
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        const isPayingMember = data.user_metadata?.isPayingMember || false;
        setIsPayingMember(isPayingMember);

        const user_id = data.user_id;
        setExtraData({ user_id })
      } catch (error) {
        console.error('Error fetching metadata:', error);
      } finally {
        setIsPayingMemberLoading(false);
      }
    };

    if (user) {
      fetchMetadata();
    }

    getUserRoles();
  }, [isAuthenticated, user, isLoading, error, getAccessTokenSilently]);

  useEffect(() => {

  }, [user, isLoading, isAuthenticated]);

  if (error) {
    logout(); // Log out and reset Auth0 session
    return <Navigate to="/login" />;
  }

  if (isLoading || rolesLoading || (isPayingMemberLoading && isAuthenticated)) return <div>Loading...</div>;

  const hasRequiredRole = requiredRoles ? requiredRoles.some(role => userRoles.includes(role)) : true;

  // RE-ENABLE TO FORCE NOT PAYING CUSTOMERS TO PAY SCREEN
  // if (isAuthenticated && !isPayingMember) {
  //   return <Navigate to="/payment-request" />;
  // }

  return isAuthenticated && hasRequiredRole ? children : <Navigate to="/login" />;
};

const App = () => {
  const [appUser, setAppUser] = useState(null);
  const [extraData, setExtraData] = useState(null);

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      audience={`https://${AUTH0_DOMAIN}/api/v2/`}

      redirectUri={window.location.origin}
      scope="profile email read:current_user update:current_user_metadata"
    // login asks permission for these: profile, current_user, current_user_metadata
    >
      <Provider store={store}>
        <BrowserRouter>
          <div>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/registration-success" element={<RegistrationSuccess />} />
              <Route path="/settings/billing" element={<Billing />} />
              <Route path="/payment-request" element={<PuddlePayment />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
              <Route path="/*" element={<AuthenticatedRoute setAppUser={setAppUser} setExtraData={setExtraData} requiredRoles={undefined}> <Home user={{ ...appUser, ...extraData }} /></AuthenticatedRoute>} />
              <Route path="/fakedb" element={<AuthenticatedRoute setAppUser={setAppUser} requiredRoles={['admin']}> <KnownFakesPage /></AuthenticatedRoute>} />
              <Route path="/whatsapp-util" element={<AuthenticatedRoute setAppUser={setAppUser} requiredRoles={['admin']}> <WhatsappMessageSender /></AuthenticatedRoute>} />
            </Routes>
          </div>
          <Modal />
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  );
};

export default App;