import React, { useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useAuth0 } from "@auth0/auth0-react";

import './TextAnalysis.css';
import Button from '../../Button/Button'

function TextAnalysis({ }) {
  const [inputText, setInputText] = useState('');
  const [textAnalysis, setTextAnalysis] = useState([]);
  const [textStatus, setTextStatus] = useState({});
  const [isAnalysisLoading, setIsAnalysisLoading] = useState(false);

  const { user } = useAuth0();

  const updateTextArea = e => {
    setInputText(e.target.value);
  }

  const getTextAnalysis = async () => {
    setIsAnalysisLoading(true);

    try {
      const response = await axios.post("/api/text/analysis", { text: inputText, user });

      const textHtmlArray = response.data.sentences.map((sentence, index) => {
        const { isAiConfidece } = sentence;
        const sentenceClass = classNames('highlighted-text', { "highlighted-text-high": isAiConfidece === "high", "highlighted-text-medium": isAiConfidece === "medium" });

        return <span key={index} className={sentenceClass} title={`There is a ${sentence.isAiConfidece} probability that this sentence is AI-generated`}>{sentence.sentence}</span>
      });

      const statusDescriptionPrefix = `Our analysis concludes with ${response.data.totalScore.confidece} confidance that this text`;
      let statusDescriptionSuffix = 'was generated by AI';

      if (response.data.totalScore.type === 'human') {
        statusDescriptionSuffix = `was created by a human`;
      } else if (response.data.totalScore.type === 'mixed') {
        statusDescriptionSuffix = `was is a mix of human create and AI generated text`;
      }

      setTextAnalysis(textHtmlArray);
      setTextStatus({
        status: response.data.totalScore.type,
        description: `${statusDescriptionPrefix} ${statusDescriptionSuffix}`
      })
    } catch (err) {
      console.log('error - ', err?.message);
    }

    setIsAnalysisLoading(false);
  }

  const indicatorClasses = classNames("text-analysis-result-indicator", {"text-analysis-indicator-green": textStatus?.status === "human", "text-analysis-indicator-yellow": textStatus?.status === "mixed", "text-analysis-indicator-red": textStatus?.status === "ai"})

  return <div className="text-analysis-wrapper">
    <textarea
      placeholder='Enter text here...'
      className="text-area-content"
      onChange={updateTextArea}
    />
    <Button onClick={getTextAnalysis} isLoading={isAnalysisLoading} disabled={inputText?.length < 24}>Detect origin</Button>
    {textAnalysis.length > 0 && <div className="text-analysis-result-container">
      <div className="text-analysis-result-header">
        <div className="text-analysis-result-title">Text analysis</div>
        <div className={indicatorClasses} title={textStatus?.description || null} />
      </div>
      <div className="text-analysis-result-body">{textAnalysis.map(text => text)}</div>
    </div>}
  </div>
}

export default TextAnalysis;
