import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import '../styles/TopBar.css';

// import aiLightImage from '../assets/images/ailight-logo.png';
import aiLightImage from '../assets/images/ailight-robot-logo.png';
import paMediaImage from '../assets/images/pa_media.png';
import reutersImage from '../assets/images/reuters.jpeg';
import defualtImage from '../assets/images/AILight-Icon.jpeg';
import { ReactComponent as BackIcon } from '../assets/icons/log-out.svg';

import LogoutIcon from '@mui/icons-material/Logout';
import SimpleDropdown from './SimpleDropdown/SimpleDropdown';

const getUserImage = (user) => {
  if (user?.email?.includes('pa_media')) {
    return paMediaImage
  }
  
  if (user?.email?.includes('reueters')) {
    return reutersImage
  }

  if (user?.picture) {
    return user.picture;
  }
  return defualtImage;
}
const Topbar = ({ className }) => {
  const { logout } = useAuth0();
  const { user } = useAuth0();
  const userImage = getUserImage(user);
  const classNames = !!className ? `top-bar ${className}` : 'top-bar';
  const options = [{ Icon: BackIcon, label: "Logout", onClick: () => logout({ returnTo: `${window.location.origin}/login` })}];

  return (

    <div className={classNames}>
      <Link to="/" className="ailight-logo" >
      <img src={aiLightImage} alt="AiLight Logo" className='main-logo' />
      </Link>
      {user? (
      <div className="top-bar-right">
        <SimpleDropdown options={options}>
          <img src={userImage} alt="User" className="user-image" />
        </SimpleDropdown>
      </div>
      ) : (<div/>)}
    </div>
  );
}

export default Topbar