import React, { useState } from 'react';
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

import './NavCategory.css';
import { ReactComponent as Chevron } from '../../../assets/icons/chevron-right.svg';
import NavItem from './NavItem';

function NavCategory({ category, isExpanded, toggleCategory, selectItem, selectedItem }) {
  const { navCategoryName, id, Icon, navChildren } = category;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const chevronClasses = classNames("chevron-icon", {
    "chevron-icon-active": isExpanded,
  });

  const itemListClasses = classNames("nav-item-list", { "nav-item-list-open": isExpanded })

  return (
    <div className='nav-wrapper'>
      <div className='nav-element' onClick={() => { toggleCategory(id) }}>
        <div className='description-wrapper'>
          <Icon />
          {navCategoryName}
        </div>
        <Chevron className={chevronClasses} />
      </div>
      <div className={itemListClasses} >
        {navChildren.map(item =>
          <NavItem key={item.path} item={item} isSelected={pathname === item.path} selectItem={() => { navigate(item.path) }} />
        )}
      </div>
    </div>
  );
}

export default NavCategory;
