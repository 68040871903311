import React, { useEffect } from 'react';
import { initializePaddle } from '@paddle/paddle-js';

import FullScreenLoader from '../components/FullScreenLoader/FullScreenLoader';
import TopBar from '../components/TopBar';

const PADDLE_ENV = process.env.REACT_APP_PADDLE_ENV || window._env_.REACT_APP_PADDLE_ENV;
const PADDLE_API_KEY = process.env.REACT_APP_PADDLE_API_KEY || window._env_.REACT_APP_PADDLE_API_KEY;
const PADDLE_PRODUCT_ID_BEGINNERS = process.env.REACT_APP_PADDLE_PRODUCT_ID_BEGINNERS || window._env_.REACT_APP_PADDLE_PRODUCT_ID_BEGINNERS;
const BASE_URL = process.env.REACT_APP_BASE_URL || window._env_.REACT_APP_BASE_URL;

const PuddlePayment = () => {
  // Download, initialize and launch Paddle instance
  useEffect(() => {
    initializePaddle({ environment: PADDLE_ENV, token: PADDLE_API_KEY }).then(
      (paddleInstance) => {
        if (paddleInstance) {
          paddleInstance?.Checkout.open({
            items: [{ priceId: PADDLE_PRODUCT_ID_BEGINNERS, quantity: 1 }],
            settings: {
              theme: "dark",
              successUrl: `${BASE_URL}/payment-success`
            }
          });
        }
      },
    );


  }, []);

  return <div>
    <TopBar className='top-bar top-bar-front-layer' />
    <FullScreenLoader />
  </div>
};

export default PuddlePayment;
