import React from 'react'

import './FullScreenLoader.css';
import { ReactComponent as Spinner } from '../../assets/icons/loader-circle.svg';


const FullScreenLoader = () => {
  return <div className="full-screen-loader-wrapper">
    <Spinner className="basic-spinner" />
  </div>
}

export default FullScreenLoader