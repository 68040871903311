import React, { useState } from 'react';
import axios from 'axios';
import { Alert } from 'react-bootstrap';

import './MediaAnalysis.css';
import FileSelectorButton from '../../../components/Selectors';
import SearchResultsSection from "../../../components/SearchResultsSection/SearchResultsSection";
import Button from "../../../components/Button/Button"


function MediaAnalysis({
  getMediaRequestCb,
  openPeriodicSearchModal,
  displayImageUrl,
  setDisplayImageUrl,
  setArticleClaim,
  showSearchResults,
  singleSearchResults,
  mediaAnalysisModalIsOpen,
  singleMediaAnalysisResults,
  resetSearch,
  analysisStatus
}) {

  const [mediaUrl, setMediaUrl] = useState('');
  const [localMedia, setLocalMedia] = useState(null);
  const [processStatus, setProcessStatus] = useState('idle');
  const [mediaType, setMediaType] = useState(null);
  const [error, setError] = useState('');
  const [isUrlLoading, setIsUrlLoading] = useState(false);

  // Function to generate a thumbnail from a video file
  const generateThumbnail = (file) => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.addEventListener('loadeddata', () => {
      video.currentTime = 3;
    });

    video.addEventListener('seeked', () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imageDataURL = canvas.toDataURL('image/png');

      setDisplayImageUrl(imageDataURL);

      URL.revokeObjectURL(video.src);
    });
  };
  // Function to receive file information and set it to the state
  const handleFileSelect = (file) => {
    resetMediaState();
    console.log('Selected local file:', file.name);

    //create a thumbnail for the video
    if (file && file.type.startsWith('video')) {
      generateThumbnail(file);
      setMediaType('video');
    }
    //create a thumbnail for the image
    else if (file && file.type.startsWith('image')) {
      const reader = new FileReader();
      reader.onloadend = () => { setDisplayImageUrl(reader.result) };
      reader.readAsDataURL(file); // Start reading the file as Data URL
      setMediaType('image');
    }
    setLocalMedia(file); // set the state to the file object
    setMediaUrl(''); //clean the media url
    setProcessStatus('new-media-ready'); // a new search flow should start
  };

  const handleUrlMetadata = async (url, metadata) => {
    console.log('Metadata:', metadata);
    // Set image from article
    if (metadata?.preview !== undefined) {
      setMediaUrl(metadata.preview);
      setDisplayImageUrl(metadata.preview);
      setError('');
      setProcessStatus('new-media-ready'); // a new search flow should start
    }
    // Set summary from article
    let urlContext;
    if (metadata?.summary !== undefined && metadata.summary.length > 8) {
      urlContext = metadata.summary;
    }
    else if (metadata?.description !== undefined && metadata.description.length > 8) {
    }
    else if (metadata?.title !== undefined && metadata.title.length > 8) {
      urlContext = metadata.title;
    }
    else if (metadata?.text !== undefined && metadata?.text.length > 4) {
      urlContext = metadata.text;
    }
    if (urlContext) {
      setArticleClaim({ claim: urlContext });
    }
    // Init DB with article metadata
    const mediaUuid = await getMediaRequestCb(url, false, 'article', metadata);
    // resetMediaState();
    if (mediaUuid === undefined) {
      setError('Failed to extract media from item');
    }
  };

  function isImageUrl(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);  // The URL points to an image
      img.onerror = () => resolve(false); // The URL does not point to an image
      img.src = url;
    });
  }
  const checkUrlType = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if (response.ok) {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.startsWith('image')) {
          return 'image';
        } else if (contentType && contentType.startsWith('text/html')) {
          return 'article';
        } else {
          return 'unsupported';
        }
      }
    } catch (error) {
      // try to check url with no-cors mode. TODO: should be fixed in nginx config
      // This is true for some websites that don't allow CORS like facebook
      try {
        const response = await fetch(url, { method: 'HEAD', mode: 'no-cors' });
        return await isImageUrl(url) ? 'image' : 'article';
      } catch (error) {
        return 'unsupported';
      }
    }
  };

  const resetMediaState = async () => {
    setLocalMedia(null);
    setMediaUrl('');
    setDisplayImageUrl(null);
    setProcessStatus('idle');
    setMediaType(null);
    setArticleClaim(null);
    setError('');
  }

  const handleURLInput = async (url) => {
    setIsUrlLoading(true);
    resetMediaState();
    console.log('Selected url:', url);
    const urlType = await checkUrlType(url);
    if (urlType === 'image') {
      let imageMetadata = { media: [{ type: 'image', url, preview: url }] };
      handleUrlMetadata(url, imageMetadata);
    } else if (urlType === 'article') {
      // Get metadata from an article
      let metadata_response = await axios.post("/api/url-metadata", { url: url });
      handleUrlMetadata(url, metadata_response.data.metadata);
    }
    else {
      console.log('INVALID');
      setError(url === '' ? '' : 'Invalid URL. Please enter a valid image/video/article URL.');
      setMediaUrl('');
      setDisplayImageUrl(null);
      setProcessStatus('idle'); // a new search flow should start
    }
    setIsUrlLoading(false);
  };

  const initiateAnalysis = async () => {
    // if localMedia is not null, upload the file => search the file
    let mediaUuid = null;
    if (localMedia !== null) {
      setProcessStatus('in-progress');
      mediaUuid = await uploadLocalFile()
    }
    else {
      setProcessStatus('in-progress');
      mediaUuid = await getMediaRequestCb(mediaUrl, false, mediaType);
    }
  };

  const uploadLocalFile = async () => {
    setProcessStatus('uploading');
    // Try to upload the file
    const mediaUuid = await getMediaRequestCb(localMedia, true, mediaType);
    setProcessStatus(mediaUuid !== null ? 'uploaded' : 'idle');
    return mediaUuid;
  }

  const resetAnalysis = () => {
    resetMediaState();

    if (resetSearch) {
      resetSearch();
    }
  }

  return (
    showSearchResults && singleSearchResults !== null ?
      <SearchResultsSection searchResults={singleSearchResults} showMediaValidation={mediaAnalysisModalIsOpen}
        mediaAnalysisResults={singleMediaAnalysisResults} imageUrl={displayImageUrl} resetAnalysis={resetAnalysis} isMetadataLoading={analysisStatus === 'Extracting Metadata'} />
      :
      <div className="media-analysis-form">
        <label htmlFor="monitor-content-input" className='media-input-title'>Media analysis</label>
        <div className="media-input-wrapper" >
          <input
            type="textbox"
            id="monitor-content-input"
            className="media-content-input"
            placeholder="Paste media url here..."
            value={mediaUrl}
            onChange={(e) => handleURLInput(e.target.value)}
            disabled={isUrlLoading}
          />
          <span className="or-seperator">or</span>
          <FileSelectorButton
            buttonText="Browse PC..." className="browse-pc-button"
            onFileSelect={handleFileSelect}
          />
        </div>
        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}

        <div className="media-preview-wrapper">

          <div> {displayImageUrl && <img className='preview-image-wrapper' src={displayImageUrl} alt="thumbnail preview" />}
          </div>
        </div>

        <div className="monitor-content-section">
          <div className="contextual-text-section">
          </div>

          {(['new-media-ready', 'in-progress', 'uploading', 'uploaded'].includes(processStatus) /*&& (localMedia !== null || mediaUrl !== '')*/) ? (
            <div>
              {(['new-media-ready', 'completed', 'uploading', 'uploaded'].includes(processStatus)) ? (
                <div className="button-container">
                  <Button onClick={initiateAnalysis}
                    disabled={processStatus === 'completed'}
                  >Analyze {mediaType}</Button>
                  <Button onClick={openPeriodicSearchModal}>Periodic Search</Button>
                </div>
              ) : (
                <button className="upload-button"
                >Searching...</button>
              )}

            </div>
          ) : (<div />)}
        </div>
      </div>
  );
}

export default MediaAnalysis;
