import React, { useState, useEffect, useRef } from 'react';

import './SimpleDropdown.css';

const SimpleDropdown = ({ children, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Ref to track the dropdown container

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={dropdownRef} style={{ position: "relative", display: "inline-block" }}>
      <div onClick={toggleDropdown} style={{ cursor: "pointer" }}>
        {children}
      </div>

      {isOpen && (
        <ul className="simple-dropdown-list">
          {options.map((option, index) => (
            <li className="simple-dropdown-item"
              key={index}
              onClick={option.onClick}
            >
              {option.Icon && <option.Icon className="simple-dropdown-item-icon" />}
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SimpleDropdown;
